import config from 'config';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useAppDispatch } from 'reducer';
import { hideLoading, showLoading } from 'reducer/slices/loadingSlice';
import { hidePopup, showPopup } from 'reducer/slices/popupSlice';
import gather from 'tools/gather';
import AddBalance from './AddBalance';

const tabsEnum = {
  wallets: 'Wallets',
  transactions: 'Transactions',
  vouchers: 'Vouchers',
};

const View = ({ data }: any) => {
  const dispatch = useAppDispatch();
  const [tab, setTab] = useState(tabsEnum.wallets);
  const [wallet, setWallet] = useState([]);
  const [transaction, setTransaction] = useState([]);
  const [voucher, setVoucher] = useState([]);

  const tabs = () => {
    const rows: React.ReactElement[] = [];

    Object.entries(tabsEnum).forEach((e, i) => {
      rows.push(
        <span key={i} className={e[1] === tab ? 'active' : ''} onClick={() => setTab(e[1])}>
          {e[1]}
        </span>,
      );
    });

    return rows;
  };

  const openChangeAmount = (e: any) => {
    dispatch(hidePopup());
    setTimeout(() => {
      dispatch(showPopup(<AddBalance data={e} />));
    }, 300);
  };

  const listWallet = () => {
    const rows: React.ReactElement[] = [];

    wallet.forEach((e: any, i: number) => {
      if (!e.isAlias && !e.isSingle) {
        rows.push(
          <tr key={i}>
            <td>{e.coin.symbol}</td>
            <td>{e.amount}</td>
            <td>{e.blocked}</td>
            <td>{e.free}</td>
            <td onClick={() => openChangeAmount(e)} className='changeAmount'>
              CHANGE AMOUNT
            </td>
          </tr>,
        );
      }
    });

    return rows;
  };

  const listTransaction = () => {
    const rows: React.ReactElement[] = [];

    transaction.forEach((e: any, i: number) => {
      rows.push(
        <tr key={i}>
          <td>{e.coin.symbol}</td>
          <td>{e.amount}</td>
          <td>{e.fee}</td>
          <td>{e.side}</td>
          <td>{e.type}</td>
          <td>{e.status}</td>
          <td>{moment(e.createdAt).format('YYYY-MM-DD HH:mm:ss')}</td>
        </tr>,
      );
    });

    return rows;
  };

  const listVoucher = () => {
    const rows: React.ReactElement[] = [];

    voucher.forEach((e: any, i: number) => {
      rows.push(
        <tr key={i}>
          <td>{e.coin.symbol}</td>
          <td>{e.code}</td>
          <td>{e.amount}</td>
          <td>{e.fee}</td>
          <td>{moment(e.createdAt).format('YYYY-MM-DD HH:mm:ss')}</td>
        </tr>,
      );
    });

    return rows;
  };

  const getData = async (address: string) => {
    const result = await gather(`${config.apiUrl}/v1/admin/${address}?page=1&limit=100&user=${data._id}`, true).get();
    if (result.code === 200) {
      return result.data.items.data;
    }
    return [];
  };

  const init = async () => {
    dispatch(showLoading());

    await Promise.all([getData('wallet'), getData('transaction'), getData('voucher')]).then((results) => {
      setWallet(results[0]);
      setTransaction(results[1]);
      setVoucher(results[2]);
    });

    dispatch(hideLoading());
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <div className='login loginAuto'>
      <div className='inputLogin'>
        <span>Email</span>
        <div>
          {data.email} || {data.telegram}
        </div>
      </div>
      <div className='tabs'>{tabs()}</div>
      <div className='contentTab'>
        {tab === tabsEnum.wallets ? (
          <table className='tableData'>
            <tbody>
              <tr>
                <td>Coin</td>
                <td>Amount</td>
                <td>Blocked</td>
                <td>Free</td>
                <td>Setting</td>
              </tr>
              {listWallet()}
            </tbody>
          </table>
        ) : tab === tabsEnum.transactions ? (
          <table className='tableData'>
            <tbody>
              <tr>
                <td>Symbol</td>
                <td>Amount</td>
                <td>Fee</td>
                <td>Side</td>
                <td>Type</td>
                <td>Status</td>
                <td>Date</td>
              </tr>
              {listTransaction()}
            </tbody>
          </table>
        ) : (
          <table className='tableData'>
            <tbody>
              <tr>
                <td>Symbol</td>
                <td>Code</td>
                <td>Amount</td>
                <td>Fee</td>
                <td>Date</td>
              </tr>
              {listVoucher()}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default View;
